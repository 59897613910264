/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable curly */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable object-shorthand */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { AESEncryptDecryptService } from '../AESEncryptDecrypt/aesencrypt-decrypt-service.service';
import { CookieService } from 'ngx-cookie-service';
import { BYPASS_LOG } from 'src/app/interceptors/api-requests.interceptor';

interface AuthResponse {
  jwt: string;
  user: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {



  domain: string = environment.api
  auth: string = this.domain + '/auth/local';

  isAdmin: boolean = false;
  private loginTracker = new BehaviorSubject(this.checkIfLoggedIn());
  private roleTracker = new BehaviorSubject(this.checkIfAdmin());

  roleStatus$ = this.roleTracker.asObservable();
  loggedInStatus$ = this.loginTracker.asObservable();

  constructor(private http: HttpClient, private ss: StorageService, private aes: AESEncryptDecryptService, private cookieSercvice: CookieService) { }


  checkIfLoggedIn() {
    if (this.cookieSercvice.check('cookies-session') && this.cookieSercvice.check("cookies-session-info")) return true
    return false;
  }

  login(identifier: string, password: string) {
    console.log(identifier, password);
    return this.http.post<AuthResponse>(this.auth, { identifier, password }, { context: new HttpContext().set(BYPASS_LOG, true) });
  }

  register(username: string, first_name: string, last_name: string, email: string, password: string) {
    return this.http.post<any>(this.auth + '/register', { "username": username, "first_name": first_name, "last_name": last_name, "email": email, "password": password, "followings": [1] });
  }

  persistUser(resp: AuthResponse) {
    let arr: any =
    {
      'id': resp.user.id,
      'email': resp.user.email,
      'username': resp.user.username,
      'avatar': resp?.user?.avatar,
      'cover': resp?.user?.cover,
      'role': resp?.user?.role?.name,
    };

    this.ss.setItem("cookies-session", resp.jwt)
    this.getPersistedUser()
  }

  getPersistedUser() {
    return new Promise((resolve, reject) => {
      if (this.ss.getItem('cookies-session') == null) return
      let usr = this.retrieveUser().subscribe({
        next: (res: any) => {
          let arr: any =
          {
            'email': res.email,
            'username': res.username,
            'firstName': res.first_name,
            'lastName': res.last_name,
            'avatar': res?.avatar?.url,
            'role': res?.role?.name,
            'cover': res?.cover?.url,
            'id': res?.id
          };

          this.ss.getItem('cookies-session-info')

          resolve(arr)
        }, error: (e: any) => {
          if (e) reject()
          this.logout()
        }
      })
    })
  }

  checkIfAdmin() {
    if (this.cookieSercvice.check('cookies-session') && this.cookieSercvice.check("cookies-session-info")) {
      let cookie: any = this.aes.decrypt(this.cookieSercvice.get('cookies-session-info'));
      cookie = JSON.parse(cookie)
      if (cookie.role == 'Administrator') {
        this.isAdmin = true
      } else this.isAdmin
    }
  }

  getPersistedToken(): string {
    let token: any = this.cookieSercvice.get('cookies-session');
    return this.aes.decrypt(token)
  }

  retrieveUser() {
    return this.http.get<AuthResponse>(this.domain + "/users/me?populate=deep,2", this.getAuthHeader());
  }

  getLoggedUser() {
    if (this.ss.getItem('cookies-session-info') !== null) return false
    let user: any = this.ss.getItem('cookies-session-info')
    user = this.aes.decrypt(user);
    user = JSON.parse(user)

    return user
  }

  getAuthHeader() {
    return {
      headers: { 'Authorization': `Bearer ${this.getPersistedToken()}`, 'jwtRequired': 'true' }
    };
  };


  getLiveUser() {
    if (!this.checkIfLoggedIn()) return
    this.retrieveUser().subscribe((res: any) => {
      let arr: any =
      {
        'email': res.email,
        'username': res.username,
        'firstName': res.first_name,
        'lastName': res.last_name,
        'avatar': res?.avatar?.url,
        'role': res?.role?.name,
        'cover': res?.cover?.url,
        'telephone': res?.telephone,
        'favorites': res?.favorites,
        'id': res?.id
      };

      this.ss.setItem('cookies-session-info', arr)
    })
    return this.getLoggedUser()
  }


  logout() {
    this.ss.removeItem("cookies-session");
    this.ss.removeItem("cookies-session-info");

    if (this.ss.getItem("cookies-session") !== null && this.ss.getItem("cookies-session-info") !== null) {
      this.ss.clear()
      location.reload()
    }
  }


  verifyAccount(confirmation: string) {
    return this.http.get<any>(this.domain + '/auth/email-confirmation?confirmation=' + confirmation);
  }

  sendResetPasswordCode(email: any) {
    return this.http.post<any>(this.domain + '/auth/forgot-password', { email: email });
  }

  resetPassword(code: string, password: any, passwordConf: any) {
    let data: any = {
      code: code,
      password: password,
      passwordConfirmation: passwordConf
    }
    return this.http.post<any>(this.domain + '/auth/reset-password', data);
  }

}

/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable max-len */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ListingsService } from 'src/app/services/listings/listings.service';

@Component({
  selector: 'app-amenities-modal',
  templateUrl: './amenities-modal.component.html',
  styleUrls: ['./amenities-modal.component.scss'],
})
export class AmenitiesModalComponent implements OnInit, OnDestroy {

  amenities: Array<any> = [];
  loaderActive: boolean = true;
  a: any;

  constructor(private modalCtrl: ModalController, private service: ListingsService, private router: Router, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getAmenities(this.router.url.split('\/')[4]);
  }

  getAmenities(id: any) {
    const err = document.getElementById('err');

    this.a = this.service.getAmenitiesFromListing(id).subscribe((res: any) => {
      if (res.data?.attributes?.amenities?.data[0] !== undefined) {
        this.amenities = res.data?.attributes?.amenities?.data;
        console.log(res);
        this.loaderActive = false;
      } else {
        err.style.display = 'block';
        this.loaderActive = false;
      }
    });
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  ngOnDestroy(): void {
    this.a.unsubscribe();
  }
}

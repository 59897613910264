/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { NormalizationService } from '../strapi/normalization.service';

interface StrapiResponse {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class ListingsService {

  domain = environment.api;
  private populate = { params: new HttpParams().set('populate', 'deep') };

  constructor(private http: HttpClient, private ns: NormalizationService) { }

  getlistings(): Observable<any[]> {
    return this.http.get<StrapiResponse>(
      this.domain + '/listings/',
      this.populate
    ).pipe(this.ns.restructureArrayedAttributes());
  }


  getGalleryFromListing(id: number) {
    return this.http.get<StrapiResponse>(
      this.domain + '/listings/' + id + '?fields=id&populate=gallery',
    );
  }

  getSingleListing(id: number) {
    return this.http.get(this.domain + '/listings/' + id);
  }

  getListingTypes() {
    return this.http.get(this.domain + '/singleListing/getListingTypes');
  }

  getAmenitiesFromListing(id: any) {
    return this.http.get<StrapiResponse>(
      this.domain + '/listings/' + id + '?populate=amenities&fields=id'
    );
  }

  getListingType(type: number) {
    return this.http.get(this.domain + '/singleListing/getListingType?type=' + type);
  }

  getDescription(id: any) {
    return this.http.get<StrapiResponse>(
      this.domain + '/listings/' + id + '?fields=description'
    );
  }


  addAmenitiesToListing(listing_id: any, amenity_id: any) {
    return this.http.get(this.domain + '/listings/addAmenitiesToListing?id=' + listing_id + '&amenity_id' + amenity_id);
  }

  getAmenities() {
    return this.http.get(this.domain + '/listings/getAmenities');
  }

  singleListing(listing_id: any) {
    return this.http.get(this.domain + '/singleListing/singleListing?id=' + listing_id);
  }
}

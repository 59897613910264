/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {
  sk = "eyuz5598V5SgU&qHOL%KAPI^KALHMERASTRAPIBZFFYER" + environment.appVer;
  constructor() { }

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.sk.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.sk.trim()).toString(CryptoJS.enc.Utf8);
  }

};

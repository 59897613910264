import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private namespace = 'wfs_a_JdUQfTjWnZr4u7xKbPeShVmYq3t6w9z$C';

  constructor() { }

  getItem(key: string): string | null {
    return window.localStorage.getItem(`${this.namespace}_${key}`);
  }

  setItem(key: string, value: string) {
    window.localStorage.setItem(`${this.namespace}_${key}`, value);
  }

  removeItem(key: string) {    
    window.localStorage.removeItem(`${this.namespace}_${key}`);
  }

  clear() {
    window.localStorage.clear();
  }

  getNamespace() {
    return this.namespace
  }

  // rand_namespace() {
  //   var dt = new Date().getTime();
  //   var uuid = 'xxxxxxxx4_xxx_xxxx'.replace(/[xy]/g, function (c) {
  //     var r = (dt + Math.random() * 16) % 16 | 0;
  //     dt = Math.floor(dt / 16);
  //     return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  //   });
  //   return uuid;
  // }
}

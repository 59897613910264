// This file can be replaced during build by using the `fileReplacements` array.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVer: 'v1.05.01.6',
  // domain: 'http://localhost:1337',
  // api: 'http://localhost:1337/api',
  domain: 'https://m.buzzyfester.com',
  api: 'https://api.buzzyfester.com/api',
  // eslint-disable-next-line max-len
  accessAPI: 'p0294e442c5f59f5fb2b30a4b490c30ce9ffed9008b66d25087df1552929f9432d2a6acb1beba57661d4987ac7f71a1634926efe702513d1cfdadaca18da50e965cff9eebfaa3493d8a1af33e7e9576c61e57ed20f677f9d2ecfda10aba9f3e4e70003c64469abaa5eeb40cf7c014cd88b85a2088163f6ca8951eea8d9955e3b7',
  cookiesState: 'localhost',
};

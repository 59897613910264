/* eslint-disable curly */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable eqeqeq */
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpContextToken,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth/auth.service';
export const BYPASS_LOG = new HttpContextToken(() => false);


export class ApiRequestsInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(BYPASS_LOG) === true) return next.handle(req);
    if (req.headers.get("jwtRequired") || this.auth.checkIfLoggedIn() == true) {
      if (this.auth.checkIfLoggedIn() == true) {
        if (!req.headers.get('Authorization')) {
          const jwtEverywhere = req.clone({ headers: req.headers.append('Authorization', `Bearer ${this.auth.getPersistedToken()}`) });
          return next.handle(jwtEverywhere);
        }
      }
      return next.handle(req);
    }

    const clonedRequest = req.clone({ headers: req.headers.append('Authorization', `Bearer ${environment.accessAPI}`) });
    return next.handle(clonedRequest);
  }
}

/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/type-annotation-spacing */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ListingsService } from 'src/app/services/listings/listings.service';

@Component({
  selector: 'app-modal1',
  templateUrl: './modal1.component.html',
  styleUrls: ['./modal1.component.scss'],
})
export class Modal1Component implements OnInit, OnDestroy {
  description!: string;
  loaderActive: boolean = true;
  d: any;

  constructor(private modalCtrl: ModalController, private service: ListingsService, private router: Router) { }

  ngOnInit() {
    this.getDesc(this.router.url.split('\/')[4]);
  }


  getDesc(id: any) {
    const err = document.getElementById('err');


    this.d = this.service.getDescription(id).subscribe((res: any) => {
      if (res.data?.attributes?.description !== undefined) {
        this.description = res.data?.attributes?.description;
        this.loaderActive = false;
      } else {
        err.style.display = 'block';
        this.loaderActive = false;
      }
    });
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  ngOnDestroy(): void {
    this.d.unsubscribe();
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './shared/components/footer/footer.component';
import { Modal1Component } from './shared/components/modals/modal1/modal1.component';
import { AmenitiesModalComponent } from './shared/components/modals/amenities-modal/amenities-modal.component';
import { FormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ApiRequestsInterceptor } from './interceptors/api-requests.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth/auth.service';

@NgModule({
  declarations: [AppComponent, FooterComponent, Modal1Component, AmenitiesModalComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(({
      mode: 'md',
      // animated:false,
    })),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgxSkeletonLoaderModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestsInterceptor,
      multi: true,
      deps: [AuthService]
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
